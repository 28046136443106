import { jsx as _jsx } from "react/jsx-runtime";
//
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';
// ----------------------------------------------------------------------
export default function Checkbox(theme) {
    return {
        MuiCheckbox: {
            defaultProps: {
                icon: _jsx(CheckboxIcon, {}),
                checkedIcon: _jsx(CheckboxCheckedIcon, {}),
                indeterminateIcon: _jsx(CheckboxIndeterminateIcon, {}),
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    padding: theme.spacing(1),
                    ...(ownerState.size === 'small' && {
                        '& svg': { width: 20, height: 20 },
                    }),
                    ...(ownerState.size === 'medium' && {
                        '& svg': { width: 24, height: 24 },
                    }),
                }),
            },
        },
    };
}
